import React, { useState, useEffect } from "react";
import { handleErrorResponse } from "../../app/apiSlice";
import {
  useCreateCommentMutation,
  useMayCommentQuery,
} from "./commentApiSlice";
import {
  useCurrentUserQuery,
  useSessionTokenQuery,
} from "../user/userApiSlice";
import toast from "react-hot-toast";
import { DrupalNodeSolution7thEdition } from "../../pages/{Node7thEditionVideo.path__alias}";
import CommentForm, { CommentFormValues } from "./CommentForm";
import { SubmitHandler } from "react-hook-form";
import { DrupalNodeSolution6thEdition } from "../../pages/{Node6thEditionVideo.path__alias}";
import { DrupalNodeSolution7thGlobalEdition } from "../../pages/{Node7thGlobalEditionVideo.path__alias}";
import * as Sentry from "@sentry/gatsby";

interface CommentCreateFormProps {
  contentType: string;
  node:
    | DrupalNodeSolution7thEdition
    | DrupalNodeSolution6thEdition
    | DrupalNodeSolution7thGlobalEdition;
}

const CommentCreateForm = ({ contentType, node }: CommentCreateFormProps) => {
  const { data: currentUser } = useCurrentUserQuery();
  const [showForm, setShowForm] = useState(false);
  const { data: sessionToken } = useSessionTokenQuery(undefined, {
    skip: !currentUser?.is_authenticated,
  });
  const { data: mayCommentData } = useMayCommentQuery(undefined, {
    skip: !currentUser?.is_authenticated,
  });
  const [createCommentTrigger, { isLoading }] = useCreateCommentMutation();

  useEffect(() => {
    if (sessionToken && mayCommentData?.mayComment) {
      setShowForm(true);
    }
  }, [sessionToken, mayCommentData?.mayComment]);

  const initialValues: CommentFormValues = {
    body: "",
  };

  const onSubmit: SubmitHandler<CommentFormValues> = (values) => {
    createCommentTrigger({
      sessionToken,
      contentType,
      nodeUuid: node.drupal_id,
      body: values.body,
    })
      .unwrap()
      .then((fulfilled) => {
        toast.success("Your comment has been submitted successfully!");
      })
      .catch((rejected) => {
        handleErrorResponse(rejected).forEach((message) => {
          const e = Error(message);
          e.name = "CommentCreateForm: onSubmit";
          Sentry.captureException(e);
        });
      });
  };

  if (!showForm) {
    return null;
  }

  return (
    <CommentForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      sessionToken={sessionToken}
      isLoading={isLoading}
    />
  );
};

export default CommentCreateForm;
