import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import ButtonPrimary from "../../components/ButtonPrimary";

export interface CommentFormValues {
  body: string;
}

interface CommentFormProps {
  label?: string;
  initialValues: CommentFormValues;
  onSubmit: SubmitHandler<CommentFormValues>;
  sessionToken: string | undefined;
  isLoading?: boolean;
}

const CommentForm = ({
  label = "Leave a comment",
  initialValues,
  onSubmit,
  sessionToken,
  isLoading,
}: CommentFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CommentFormValues>();

  return (
    <form data-testid="leaveCommentForm" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="body" className="font-bold text-neutral-600 block mb-1">
        {label}
      </label>
      <textarea
        id="body"
        className="border border-neutral-300 shadow p-3 w-full rounded"
        rows={5}
        defaultValue={initialValues?.body ?? null}
        {...register("body", { required: true })}
      />
      {errors?.body && (
        <p className="text-sm text-red-400 mt-1">The comment can't be empty.</p>
      )}
      <div className="mt-1">
        <ButtonPrimary size="10" disabled={!sessionToken || isLoading}>
          Submit
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default CommentForm;
