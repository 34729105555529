import { graphql, HeadProps, PageProps } from "gatsby";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import SectionTitle from "../components/SectionTitle";
import Seo from "../features/seo/Seo";
import { EDITION_7TH_GLOBAL } from "../constants";
import { useGetCommentsQuery } from "../features/comment/commentApiSlice";
import CommentCreateForm from "../features/comment/CommentCreateForm";
import CommentParent from "../features/comment/CommentParent";
import { comments7thGlobalEdition } from "../features/comment/utils";
import Footer from "../features/footer/Footer";
import Navbar from "../features/navbar/Navbar";
import Answer from "../features/solution/Answer";
import Heading from "../features/solution/Heading";
import VideoArea from "../features/solution/media-chrome/VideoArea";
import Menu from "../features/solutionMenu/Menu";
import { chapterSections7thGlobalEdition } from "../features/solutionMenu/utils";
import { useCurrentUserQuery } from "../features/user/userApiSlice";
import {
  ChapterNode,
  ChapterTerm,
  DrupalComment,
  DrupalNodeSolutionBase,
} from "../types";

export type DrupalNodeSolution7thGlobalEdition = DrupalNodeSolutionBase & {
  comment_node_7th_global_editio: {
    cid: number;
    last_comment_timestamp: number;
    comment_count: number;
  };
  field_7th_global_edition_problem: number;
  relationships: {
    field_7th_edition_node: {
      path: {
        alias: string;
      };
    };
    comment__comment_node_7th_global_editio: DrupalComment[];
    field_7th_global_edition_chapter: ChapterTerm & {
      relationships: {
        node__7th_global_edition_video: ChapterNode[];
        taxonomy_term__7th_global_edition_section: {
          field_section_name: string;
          field_section_number: string;
          drupal_internal__tid: number;
          weight: number;
          relationships: {
            node__7th_global_edition_video: {
              drupal_internal__nid: number;
              field_duration: number;
              field_7th_global_edition_problem: number;
              path: {
                alias: string;
              };
            }[];
          };
        }[];
      };
    };
  };
};

interface Solution7thGlobalEditionData {
  node7thGlobalEditionVideo: DrupalNodeSolution7thGlobalEdition;
}

const SolutionGlobalEdition = (
  props: PageProps<Solution7thGlobalEditionData>
) => {
  const { data: currentUser } = useCurrentUserQuery();
  const node = props.data.node7thGlobalEditionVideo;
  const { data: commentsJsonapi, isFetching: isFetchingComments } =
    useGetCommentsQuery({
      contentType: "7th_global_edition_video",
      uuid: node.drupal_id,
    });
  const sections = useMemo(
    () => chapterSections7thGlobalEdition(node),
    [node.drupal_id]
  );
  const comments = useMemo(
    () => comments7thGlobalEdition(node, commentsJsonapi),
    [isFetchingComments, commentsJsonapi, node]
  );

  return (
    <>
      <Navbar isSolution />
      <div className="bg-neutral-100 h-full min-h-screen">
        <div className="max-w-8xl mx-auto">
          <Menu
            chapterTerm={node.relationships.field_7th_global_edition_chapter}
            sections={sections}
            node={node}
            edition={EDITION_7TH_GLOBAL}
          />
          <div className="lg:ml-[28rem] px-4">
            <Heading node={node} />
            <Answer node={node} />
            <div className="flex justify-center my-8">
              <VideoArea
                node={node}
                chapterNodes={
                  node.relationships.field_7th_global_edition_chapter
                    .relationships.node__7th_global_edition_video
                }
              />
            </div>
            {!currentUser?.is_authenticated &&
              !node.field_sample &&
              node.field_transcript && (
                <div className="my-8">
                  <SectionTitle
                    backgroundStyle="white"
                    title="Video Transcript"
                  />
                  <p
                    dangerouslySetInnerHTML={{ __html: node.field_transcript }}
                  ></p>
                </div>
              )}
            {comments?.length > 0 && (
              <div id="section-heading--comments" className="my-8">
                <SectionTitle backgroundStyle="white" title="Comments" />
              </div>
            )}
            {comments?.map((comment) => {
              return (
                <CommentParent
                  key={comment.parent.drupal_internal__cid}
                  comment={comment}
                  contentType="7th_global_edition_video"
                />
              );
            })}
            <div className="my-8">
              <CommentCreateForm
                contentType="7th_global_edition_video"
                node={node}
              />
            </div>
          </div>
          <div className="lg:ml-[28rem] lg:mr-4">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default SolutionGlobalEdition;

export const Head = ({ data }: HeadProps<Solution7thGlobalEditionData>) => {
  const node = data.node7thGlobalEditionVideo;
  return (
    <Fragment>
      <Seo
        title={`Giancoli 7th "Global" Edition, Chapter ${data.node7thGlobalEditionVideo.relationships.field_7th_global_edition_chapter.field_chapter_number}, Problem ${data.node7thGlobalEditionVideo.field_7th_global_edition_problem}`}
        pathname={
          data.node7thGlobalEditionVideo.relationships.field_7th_edition_node
            .path.alias
        }
        description={`Giancoli 7th Global Edition solution for physics problem ${node.field_problem} in ${node.relationships.field_7th_global_edition_chapter.name}`}
      />
    </Fragment>
  );
};

export const query = graphql`
  query getSolution7thGlobalEdition($path__alias: String) {
    node7thGlobalEditionVideo(path: { alias: { eq: $path__alias } }) {
      drupal_internal__nid
      field_s3_filename
      field_s3_folder
      field_sample
      field_transcript
      created(formatString: "X")
      field_duration
      field_7th_global_edition_problem
      title
      drupal_id
      fields {
        fieldQuickAnswerProcessedKatex
      }
      field_problem
      comment_node_7th_global_editio {
        cid
        last_comment_timestamp
        comment_count
      }
      relationships {
        field_7th_edition_node {
          path {
            alias
          }
        }
        comment__comment_node_7th_global_editio {
          drupal_id
          relationships {
            uid {
              name
              drupal_internal__uid
            }
            entity_id {
              drupal_id
            }
          }
          changed(formatString: "X")
          comment_body {
            processed
            value
          }
          fields {
            commentBodyProcessedKatex
          }
          pid {
            drupal_internal__target_id
          }
          drupal_internal__cid
          thread
        }
        field_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 640, placeholder: TRACED_SVG)
            }
          }
        }
        videoThumbnailFixed: field_thumbnail {
          localFile {
            childImageSharp {
              resize(width: 640) {
                src
              }
            }
          }
        }
        field_7th_global_edition_chapter {
          drupal_internal__tid
          field_chapter_number
          name
          field_thumbnail {
            alt
          }
          description {
            value
          }
          relationships {
            solutionMenuThumbnail: field_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 64, height: 64)
                }
              }
            }
            node__7th_global_edition_video {
              field_sample
              title
              field_7th_global_edition_problem
              path {
                alias
              }
            }
            taxonomy_term__7th_global_edition_section {
              field_section_name
              field_section_number
              drupal_internal__tid
              weight
              relationships {
                node__7th_global_edition_video {
                  drupal_internal__nid
                  field_duration
                  field_7th_global_edition_problem
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
